@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');

*{
    box-sizing:border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    -ms-box-sizing:border-box;
    -o-box-sizing:border-box;
}
body {
    padding:0;
    margin: 0;
    font-family: 'Poppins', sans-serif;
    transition:all .3s ease-in-out;
    -webkit-transition:all .3s ease-in-out;
    -moz-transition:all .3s ease-in-out;
}

h1, h2, h3, h4, h5, h6{font-family: 'Poppins', sans-serif !important;font-weight:600 !important;padding:0;margin:0;color:#000;}
body p{font-family: 'Open Sans', sans-serif !important;font-size: 16px !important;line-height: 29px !important;margin:0;}
img{max-width:100%;}
a{text-decoration:none !important;}


h2{font-size:44px !important;}
h3{font-size:37px !important;}
h4{font-size:24px !important;}

.container{width:1170px;margin:0 auto;}
.title {text-align: center;}

/*  Header  */

.standard_btn {position: relative;overflow: hidden;z-index:1; background-color: transparent !important;border-radius:5px !important;font-size: 16px !important;padding: 13px 25px !important;line-height: 1 !important;min-width: auto !important;color: #fff !important;text-transform: capitalize !important;border: 2px solid #fff !important;}
.standard_btn.dark{background: #000 !important;border-color: transparent !important;}
.standard_btn::after{background: #fff;content: "";height: 155px;left: -75px;opacity: 0.2;position: absolute;top: -50px;transform: rotate(35deg);transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);width: 50px;z-index: -10;}
.standard_btn:hover::after{left: 120%;transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);}
.nav_link button{margin-left:10px;}
.header-section {background: transparent;position: fixed !important;z-index: 99;width: 100%;left: 0;right: 0;top: 0;padding:20px 0 !important;transition: padding .5s;}
.headeroverlay {display: flex;align-items: center;justify-content: space-between;width: 100%;}
.site_logo{display: flex;align-items:center;}
.site_logo .logo_text{color: #fff;font-size: 28px;padding-left: 10px;}
.nav_link{align-items:center;justify-content:end;flex-grow: 1;}
.nav_link > a, .footer_link a {cursor: pointer;font-size: 16px;color: #fff !important;margin-right:20px;position: relative;padding-bottom: 5px;font-weight: 600;}
.nav_link > a::after {position: absolute;content: '';left: 0;bottom: 0;width: 0;height: 2px;background: #fff;transition: all .3s ease-in-out;}
.nav_link a:hover::after{width:100%;}
.nav_link a:hover{color:#fff;}
.header_button button{margin-left:10px;}
.sticky-header .nav_link a::after{background-color:#000;}
.sticky-header.header-section{background-color:#fff;box-shadow: 0 0 50px rgba(0,0,0,.1);-moz-box-shadow: 0 0 50px rgba(0,0,0,.1);-webkit-box-shadow: 0 0 50px rgba(0,0,0,.1);padding:15px 0 !important;}
.sticky-header .nav_link a {color: #000 !important;}
.sticky-header .nav_link .standard_btn{border-color:#1890ff !important;color:#1890ff !important;}
.sticky_logo{display: none;}
.sticky-header .sticky_logo{display:block;}
.sticky-header .normal_logo{display:none;}
.sticky-header .nav_link .standard_btn:after {background-color:#1890ff;}
.sticky-header .standard_btn a{color:#1890FF;}
 
/* Banner Section */

.space{padding:80px 0;}
.hero_section {background: #1890FF;transition: 0.5s;-webkit-transition: 0.5s;background-size: 200% auto;position: relative;overflow: hidden;z-index:9;padding-bottom:23px;}
.hero_section:after, .inner_banner:after{position: absolute;background: url('../assets/Images/hero-overlay.png')bottom no-repeat;content: '';background-size: contain !important;height: 100%;left: -1px;right: -1px;bottom: -40px;z-index: -1;}
.banner_content{text-align:center;padding-top: 200px;}
.banner_content h1{color: #fff;font-size: 50px;font-weight: 600;margin-bottom: 25px;text-transform: capitalize;}
.banner_image{margin-top:60px;}
.comingsoon_text {display: flex;justify-content:center;align-items:center;margin: 30px 0 0;}
.comingsoon_text h3{padding-right:10px;filter: drop-shadow(3.536px 3.536px 0px rgba(0, 0, 0, .11));}
.movebounce {-webkit-animation: movebounce 3.9s linear infinite;animation: movebounce 3.9s linear infinite;box-shadow: 0 0px 4px #ccc;-webkit-box-shadow: 0 0px 4px #ccc;-moz-box-shadow: 0 0px 4px #ccc;}
@keyframes movebounce {
    0% {
      transform: translateY(0px); }
    50% {
      transform: translateY(20px); }
    100% {
      transform: translateY(0px); } 
}
@keyframes moveleftbounce {
    0% {
        transform: translateX(0px); }
    50% {
        transform: translateX(20px); }
    100% {
        transform: translateX(0px); } 
}
  
/* Service Box */
.service_content h4 {margin-bottom:10px;margin-top: 17px;}
.service_content{background-color:#fff;padding:30px;transition:all .3s ease-in-out;-webkit-transition:all .3s ease-in-out;-moz-transition:all .3s ease-in-out;}
.service_content:hover{transform: translateY(-20px);-webkit-transform: translateY(-20px);-moz-transform: translateY(-20px);box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.18);-webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.18);-moz-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.18);}
.s_icon img {width: 100px;}

/* About Sectinon */
.about_sectinon{background-color:#3aa0ff;}
.about_box{padding: 30px;background: #fff;box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.15) 0px 5px 20px 0px;}
.about_content{padding:0 50px 30px 50px;}
.about_content p {margin: 20px 0 20px;}
.about_box img{width:100%;}

/* fetures */
.title{margin-bottom: 50px;}
.title h2{color: #1890FF;}
.features_overlay {display: flex;align-items: center;flex-wrap: wrap;margin-bottom:75px;}
.features_overlay:last-child{margin-bottom:0;}
.features_overlay .features_content, .features_overlay .features_img{width:50%;} 
.features_img img{box-shadow: 0 0 10px #ccc;width:850px;}
.features_content .showcase{float: right;padding: 0 100px 0 0;max-width: 585px;}
.features_content h3{margin-bottom:20px;}
.features_section .features_overlay:nth-child(2n){flex-direction: row-reverse;}
.features_section .features_overlay:nth-child(2n) .features_content .showcase{ float:left;padding:0 0 0 100px;}
.features_img .showcaseimg{text-align: right;}
.features_section .features_overlay:nth-child(2n) .features_img .showcaseimg{ text-align:left;}

/* Faqs */
.contact_us{background: #1890ff;padding:70px 0;}
.contact_box h3{color:#fff;filter: drop-shadow(3.536px 3.536px 0px rgba(0, 0, 0, .11));margin-bottom:20px;}
.contact_box{text-align:center;}

/* footer */
.footer{background-color:#000;padding:80px 0 20px 0;}
.footer_link a{color:#b6c9dd !important;padding: 0;line-height: 1;font-weight: 600;display: block;margin-bottom:5px;}
.footer .location_box svg {font-size: 30px;width: 10%;}
.footer .location_box svg path {fill: #b6c9dd;}
.address_text p span{display: block;font-weight:600;text-transform:uppercase;}
.address_text p {color: #b6c9dd;}
.footer .location_box {display: flex;flex-wrap: wrap;padding-bottom: 20px;width:50%;}
.footer .location_box:first-child, .footer .location_box:nth-child(3){padding-right: 20px;}
.footer .location_box:last-child {margin-bottom:0;}
.address_text{width: 90%;padding-left: 10px;margin-top:-2px;}
.footer p, .footer_link a{font-size: 15px !important;line-height: 26px !important;}
.footer-top {padding-bottom: 20px;border-bottom:1px solid #131212;margin-bottom: 20px;}
.footer_copyright {display: flex;justify-content: space-between;align-items: center;}
.ctext p{font-weight:600;color: #b6c9dd;}
.ctext a{color: #ffffff;}
.ctext a:hover{color: #b6c9dd;} 
.footer_icon svg {font-size:18px;display: flex;display: inline-block;vertical-align: middle;fill: #b6c9dd;}
.footer_icon svg:last-child {margin-right: 0;}
.footer_title{color: #fff;position: relative;padding-bottom: 10px;margin-bottom: 26px !important;font-weight: 500 !important;}
.footer_title:after{position:absolute;left: 0;bottom: 0;content:"";width:35px;height:2px;background-color:#fff;}
.footer_lbox {display: flex;flex-wrap: wrap;}
.footer_icon{display:flex;}
.footer_icon a {margin: 0 5px;position: relative;width: 35px;height: 35px;display: flex;justify-content: center;align-items: center;}
.footer_icon a:after{
    border-right-color: rgba(116,159,187,0.1);
    border-left-color: rgba(116,159,187,0.1);
    content: '';position: absolute;z-index: 1;top: -1px;left: -1px;width: 100%;height: 100%;
    border-width: 1px;border-style: solid;border-radius: 50%;
    border-top-color: transparent;border-bottom-color: transparent;
    background-color: transparent;opacity: 0;transition: 0.3s, color 0s;
    animation: 2s spin_circle linear infinite;animation-play-state: running;
    animation-play-state: paused;
}
.footer_icon a:hover:after{animation-play-state: running;opacity: 1;}
@keyframes spin_circle { 0% { transform: rotate(0turn); } 100% { transform: rotate(1turn); } }
.footer_icon .twitter:after{border-right-color:#55acee;border-left-color:#55acee;}
.footer_icon .twitter:hover svg path{fill:#55acee;}
.footer_icon .facebook:after{border-right-color:#26569b;border-left-color:#26569b;}
.footer_icon .facebook:hover svg path{fill:#26569b;}
.footer_icon .linkdin:after{border-right-color:#0073b1;border-left-color:#0073b1;}
.footer_icon .linkdin:hover svg path{fill:#0073b1;}

/*  Mobile Menu  */
.navbar-toggler{background-color:#fff !important;border-radius: 0 !important;}
.offcanvas.show .navbar-nav{align-items:flex-start;}
.offcanvas.show .navbar-nav .standard_btn{border-color:#1890FF !important;color:#1890FF !important;margin:0 10px 10px 0;width:150px;}
.offcanvas.show .navbar-nav a {color: #000 !important;font-size: 18px;font-weight:600;margin:0 0 10px;padding: 0 0 5px;}
.offcanvas.show .navbar-nav{padding-left:10px;}
.offcanvas.show .header_button {margin: 10px 0 0;}
.offcanvas.show .navbar-nav .standard_btn::after, .offcanvas.show .navbar-nav a::after{background-color: #1890ff;}
.navbar-toggler:focus{box-shadow:none !important;}


/*  Inner Page Banner */
.inner_banner{position: relative;background: #1890FF;padding: 180px 0 320px;position: relative;overflow: hidden;z-index: 9;}
.inner_banner h1{font-size:50px;color:#fff;filter: drop-shadow(3.536px 3.536px 0px rgba(0, 0, 0, .11));}
   

@media only screen and (max-width:1920px){
    
}
@media only screen and (max-width:1900px){
    .container {width: 1500px;}
}
@media only screen and (max-width:1600px){
	.container {width: 1380px;}
}

@media only screen and (max-width:1440px){
	.container {width: 1230px;}
    
    h2{font-size:40px !important;}
    h3 {font-size: 35px !important;}
    .banner_content h1 {font-size: 47px;margin-bottom: 20px;}
}

@media only screen and (max-width:1360px){
	.container {width: 1170px;}

    h2{font-size:35px !important;}
    h3 {font-size: 30px !important;}
    h4 {font-size: 22px !important;}
    .banner_image {margin-top: 60px;}
    .service_content h4 {margin-bottom: 12px;margin-top: 22px;}
    .space {padding: 80px 0 50px 0;}
    .contact_us, .about_sectinon {padding: 60px 0;}
    .features_overlay {margin-bottom:60px;}
    .footer {padding: 60px 0 20px 0;}
}

@media only screen and (max-width:1200px){
	.container{width: 960px;}

    .about_content {padding: 0 30px;}
    .banner_image img{width: 650px;}
    .banner_content h1 {font-size: 42px;}
    .banner_content {width: 80%;margin: auto;}
    .features_content .showcase {padding: 0 30px 0 0;max-width: 470px;}
    .features_section .features_overlay:nth-child(2n) .features_content .showcase {padding: 0 0 0 30px;}
}
@media only screen and (max-width:999px){
	.container{width: 720px;}

    h2{font-size:30px !important;}
    h3 {font-size: 25px !important;}
    h4 {font-size: 20px !important;}
    .banner_content {width: 100%;padding-top: 180px;}
    .banner_image {margin-top: 40px;}
    .contact_us, .about_sectinon {padding: 50px 0;}
    .space {padding: 60px 0 50px 0;}
    .features_content .showcase {padding: 0 20px 0 0;max-width: 350px;}
    .standard_btn {padding: 10px 25px !important;}
    
}
@media only screen and (max-width:900px){
    
    .about_content {padding: 40px 0 20px 0;}
    .title {margin-bottom: 30px;}
    .footer-top {padding-bottom: 10px;margin-bottom: 20px;}
}
@media only screen and (max-width:767px){
	.container{width: 600px;}

    .banner_content h1 {font-size: 35px;}
    .features_overlay .features_content, .features_overlay .features_img {width: 100%;}
    .features_overlay{flex-direction:column-reverse;padding: 0 60px;}
    .features_img .showcaseimg {text-align: center;}
    .features_content .showcase, .features_section .features_overlay:nth-child(2n) .features_content .showcase {border-bottom: 1px solid #ccc;padding: 40px 0px;max-width: 100%;}
    .features_section .features_overlay:nth-child(2n) {flex-direction: column-reverse;}
    .features_section .features_overlay:last-child .features_content .showcase {border:none;padding-bottom:0 !important;}
    .contact_us, .about_sectinon {padding: 50px 0;}
    .footer_copyrights, .footer-address {flex-direction: column;}
    .address_text, .ctext p {line-height: 24px !important;}
    .footer {padding: 40px 0 20px 0;}
    .banner_content {padding-top: 130px;}
    .features_content h3 {margin-bottom: 10px;}
    .footer p, .footer_link a {font-size: 14px !important;line-height: 24px !important;}
    .footer_copyright {flex-direction: column-reverse;}
    .footer_icon {margin-bottom: 5px;}
    .footer .location_box {padding-bottom: 10px;}
}
@media only screen and (max-width:640px){
	.container{width: 420px;}

    .banner_content h1 {font-size: 30px;}
    .space {padding: 40px 0 30px 0;}
    .features_overlay {padding: 0 15px;margin-bottom: 40px;}
    .features_content .showcase, .features_section .features_overlay:nth-child(2n) .features_content .showcase {padding: 30px 0px;}
    .contact_us, .about_sectinon {padding: 30px 0;}
    .footer-top {padding-bottom: 20px;margin-bottom: 20px;}
    .footer .location_box svg {font-size: 26px;width: 9%;}
    .footer_copyright p{text-align: center;}
}
@media only screen and (max-width:600px){

    .footer .location_box {width: 80%;}
    .address_text {padding-left: 0;}
}

@media only screen and (max-width:479px){
	.container{width: 300px;}

    body p {font-size: 15px !important;line-height: 26px !important;}
    h2{font-size:25px !important;}
    .title {margin-bottom: 20px;}
    h3 {font-size: 20px !important;}
    .banner_content h1 {font-size: 25px;margin-bottom: 10px;}
    .banner_content {padding-top: 30px;}
    .about_content p {margin: 10px 0 20px;}
    .service_content, .about_box{padding: 15px;}
    .banner_content {padding-top: 100px;}
    .site_logo img, .offcanvas-header img{width: 130px;}
    .offcanvas.show .navbar-nav {padding-left: 0;}
    .navbar-toggler {padding: 2px 6px !important;}
    .hero_section::after {bottom: 0px;}
    .footer_title {margin-bottom: 15px !important;}
 
    
}
@media only screen and (max-width:400px){

}